// @ts-strict-ignore
import type { EmeraldTypes } from '@dialogue/services'
import { createAction, createReducer } from '@reduxjs/toolkit'

import type { AppointmentMetadata, AppointmentState } from './types'

export const INITIAL_STATE: AppointmentState = {
  patients: {},
}

export const getAppointments = createAction<{
  patientId: number | string
}>('@@appointments/GET_APPOINTMENTS')

export const getAppointmentsSuccess = createAction<{
  patientId: number | string
  appointments: EmeraldTypes.Appointment[]
  providers: EmeraldTypes.Provider[]
  metadata: AppointmentMetadata
}>('@@appointments/GET_APPOINTMENTS_SUCCESS')

export const getAppointmentsFailure = createAction<{
  patientId: number | string
  error: Error
}>('@@appointments/GET_APPOINTMENTS_FAILURE')

export default createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(getAppointments, (state, action) => {
    state.patients[action.payload.patientId] = {
      fetching: true,
      error: null,
      appointments:
        state.patients[action.payload.patientId]?.appointments || [],
      providerMap: state.patients[action.payload.patientId]?.providerMap || {},
      metadata: state.patients[action.payload.patientId]?.metadata,
    }
  })

  builder.addCase(getAppointmentsSuccess, (state, action) => {
    const patientState = state.patients[action.payload.patientId]

    if (!patientState) {
      return
    }

    patientState.appointments = patientState.appointments || []
    patientState.providerMap = patientState.providerMap || {}

    // Avoid duplicate appointments
    action.payload.appointments.forEach((appointment) => {
      const existingIndex = patientState.appointments.findIndex(
        (existing) => existing.id === appointment.id,
      )
      if (existingIndex === -1) {
        patientState.appointments.push(appointment)
      } else {
        patientState.appointments[existingIndex] = appointment
      }
    })

    action.payload.providers.forEach((item) => {
      patientState.providerMap[item.id] = item
    })

    patientState.fetching = false
    patientState.metadata = action.payload.metadata
  })

  builder.addCase(getAppointmentsFailure, (state, action) => {
    const patientState = state.patients[action.payload.patientId]
    if (patientState) {
      patientState.fetching = false
      patientState.error = action.payload.error
    }
  })
})
