import { createApi } from '@reduxjs/toolkit/dist/query/react'

import { API_NAMES, getDynamicBaseUrl } from './utils'

export const emeraldApi = createApi({
  tagTypes: [],
  reducerPath: 'emeraldApi',
  baseQuery: getDynamicBaseUrl(API_NAMES.EMERALD),
  endpoints: (builder) => ({
    getPractitionerMapping: builder.query({
      query: ({ practitionerId }: { practitionerId: number }) => ({
        url: `/v2/practitioner/${practitionerId}/mapping`,
      }),
      transformResponse: (response: {
        data: {
          provider_id: number
          dialogue_id: number
        }
      }) => response.data,
    }),
  }),
})

export const { useLazyGetPractitionerMappingQuery } = emeraldApi
