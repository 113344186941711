import { useCallback } from 'react'

import { CloseCircleOutlined } from '@ant-design/icons'
import {
  Avatar,
  Button,
  Modal,
  Card,
  type ModalProps,
  Typography,
  Row,
} from 'antd'
import { useTranslation } from 'react-i18next'

import { colors } from 'app/theme'

interface RemovePreferredPharmacyModalProps extends Pick<ModalProps, 'open'> {
  handleClose: () => void
  handleRemovePharmacy: () => void
}

export const RemovePreferredPharmacyModal = ({
  handleClose,
  handleRemovePharmacy,
  ...modalProps
}: RemovePreferredPharmacyModalProps) => {
  const { t } = useTranslation()
  const { t: tCommon } = useTranslation('common')

  const renderModal = useCallback<
    NonNullable<ModalProps['modalRender']>
  >(() => {
    return (
      // @see https://github.com/ant-design/ant-design/issues/37060#issuecomment-1215811726
      <Card style={{ pointerEvents: 'auto' }} data-dd-privacy="allow">
        <Card.Meta
          avatar={
            <Avatar
              icon={
                <CloseCircleOutlined
                  css={`
                    color: ${colors.error};
                  `}
                />
              }
              size="large"
              style={{ backgroundColor: 'initial' }}
            />
          }
          description={t('patientProfile.pharmacy.removeModal.subtitle')}
          title={
            <Typography.Title level={4}>
              {t('patientProfile.pharmacy.removeModal.title')}
            </Typography.Title>
          }
          css={`
            && .ant-card-meta-title {
              margin-bottom: 0px;
              margin-top: 5px;
            }

            .ant-card-meta-description {
              color: ${colors.textDark};
            }
          `}
        />
        <Row justify="end" style={{ gap: '8px' }}>
          <Button
            type="text"
            onClick={handleClose}
            data-dd-action-name="preferred-pharmacy:remove:cancel"
          >
            {tCommon('button.cancel')}
          </Button>
          <Button
            type="primary"
            danger
            onClick={handleRemovePharmacy}
            data-dd-action-name="preferred-pharmacy:remove:confirm"
          >
            {tCommon('button.remove')}
          </Button>
        </Row>
      </Card>
    )
  }, [handleClose, handleRemovePharmacy, t, tCommon])

  return <Modal destroyOnClose modalRender={renderModal} {...modalProps} />
}
