import type { EnrichedEpisode } from '@dialogue/coredata'
import type { EmergencyRoomTypes } from '@dialogue/services'
import type { Appointment } from '@dialogue/timekeeper'
import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import type { Moment } from 'moment'

import { alphaSort } from 'app/lib/sorters'
import type { ReduxState } from 'app/redux'
import type { Episode } from 'app/redux/episode-view/types'

import { makeSetForRangeAndProviders } from './schedule/common'
import {
  filterGroupScheduleActions,
  selectFilterGroupProviderIds,
} from './schedule/filter-group-schedule'
import { providerScheduleActions } from './schedule/provider-schedule'

export interface FormData {
  episode_id: string
  provider_id: number
  appointment_type: string
  start_date: Moment
  duration: number
}

export type FormSubmitData = Omit<FormData, 'duration'> & {
  episode:
    | EmergencyRoomTypes.EnrichedEpisode
    | EnrichedEpisode
    | Partial<Episode>
  end_date: Moment
}

const appointmentAdapter = createEntityAdapter<Appointment>({
  selectId: (appointment) => appointment.id,
  sortComparer: (a, b) => alphaSort(a.start_at, b.start_at),
})

const INITIAL_STATE = appointmentAdapter.getInitialState()

const setForRangeAndProviders = makeSetForRangeAndProviders(appointmentAdapter)

export const { actions: timekeeperAppointmentsActions, reducer } = createSlice({
  name: '@@timekeeper/appointments',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(providerScheduleActions.received, (state, action) => {
      setForRangeAndProviders(
        state,
        action.payload.schedule.appointments,
        [action.payload.schedule.provider_id],
        action.payload.range,
      )
    })
    builder.addCase(filterGroupScheduleActions.received, (state, action) => {
      const appointments = action.payload.schedules.flatMap(
        (schedule) => schedule.appointments,
      )
      const providerIds = action.payload.schedules.map(
        (schedule) => schedule.provider_id,
      )
      setForRangeAndProviders(
        state,
        appointments,
        providerIds,
        action.payload.range,
      )
    })
  },
})

const selectors = appointmentAdapter.getSelectors(
  (state: ReduxState) => state.timekeeper.appointments,
)

export const selectAppointmentsForProvider = createSelector(
  selectors.selectAll,
  (_state: ReduxState, providerId: number) => providerId,
  (appointmentDataMap, providerId): Appointment[] =>
    appointmentDataMap.filter((a) => a.provider_id === providerId),
)

export const selectAppointmentsForFilterGroup = createSelector(
  selectors.selectAll,
  selectFilterGroupProviderIds,
  (appointmentDataMap, providerIds) =>
    appointmentDataMap.filter((a) => providerIds.includes(a.provider_id)),
)

export default reducer
