import type { MembersApiUnsetPreferredPharmacyRequest } from '@dialogue/coredata'

import { emergencyRoomApi, Tags } from './api'

export const membersApi = emergencyRoomApi.injectEndpoints({
  endpoints: (build) => ({
    unsetPreferredPharmacy: build.mutation({
      query: ({ patientId }: MembersApiUnsetPreferredPharmacyRequest) => ({
        url: `/v1/members/${patientId}/pharmacy`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { patientId }) => [
        { type: Tags.MemberPharmacy, id: patientId },
      ],
    }),
  }),
})

export const { useUnsetPreferredPharmacyMutation } = membersApi
