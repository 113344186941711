import type { ReactNode } from 'react'

import { DeleteFilled } from '@ant-design/icons'
import {
  Button,
  Card,
  Popconfirm,
  Row,
  Space,
  Typography,
  type CardProps,
} from 'antd'
import moment, { type MomentInput } from 'moment'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { colors } from 'app/theme'

export type MemberInfoCardProps = Partial<Omit<CardProps, 'content'>> & {
  title?: string
  titleIcon?: ReactNode
  content: ReactNode
  updatedAt?: MomentInput
  editing?: boolean
  onSave?: () => void
  onCancel?: () => void
  // TODO: refactor so that onDelete must be provided if deletable: true, to make component usage clearer
  deletable?: boolean
  onDelete?: () => void
}

export interface UpdatedAtProps {
  updatedAt?: MomentInput
}

export interface LeftActionsProps {
  deletable?: boolean
  onDelete?: () => void
}

export interface RightActionsProps {
  onSave?: () => void
  onCancel?: () => void
}

const StyledCard = styled(Card)`
  user-select: text;
  display: flex;
  flex-direction: column;

  && .ant-card-head {
    min-height: 44px;
    border: none;
  }

  &&& .ant-card-head-title {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  && .ant-card-body {
    overflow-y: auto;
    padding-top: 8px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &::before,
    &::after {
      display: none;
    }
  }

  && .ant-card-actions {
    background-color: ${colors.background};
    padding: 0 16px;
    li {
      border-right: none;
    }
  }
`

const RightSpace = styled(Space)`
  float: right;
`
const LeftSpace = styled(Space)`
  float: left;
`

const EllipsisSpace = styled(Space)`
  width: 100%;
  font-size: 1.6rem;

  // needed to allow for ellipsis inside of a flex container.
  & .ant-space-item:last-child {
    min-width: 0;
    flex: 1;
  }
`

const UpdatedAt = ({ updatedAt }: UpdatedAtProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'memberProfile.profileTab',
  })
  const updatedAtDisplay = moment(updatedAt).fromNow()

  return (
    <Row
      justify="end"
      css={`
        padding-top: 4px;
      `}
    >
      <Typography
        css={`
          font-size: 1.6rem;
          color: ${colors.textLight};
        `}
      >
        {updatedAt && t('lastEdited', { dateAgo: updatedAtDisplay })}
      </Typography>
    </Row>
  )
}

const LeftActions = ({ deletable, onDelete }: LeftActionsProps) => {
  const { t: tCommon } = useTranslation('common')
  const { t: tTranslation } = useTranslation('translation', {
    keyPrefix: 'memberProfile.profileTab',
  })
  return (
    <LeftSpace>
      {deletable && (
        <Popconfirm
          title={tTranslation('noteDeleteConfirmation')}
          onConfirm={onDelete}
          okText={tTranslation('noteDelete')}
          okButtonProps={{
            // @ts-expect-error bad type from antd
            'data-testid': 'member-info-card-confirm-delete-btn',
            danger: true,
            type: 'primary',
          }}
          cancelText={tCommon('button.no')}
        >
          <Button
            size="small"
            type="text"
            danger
            data-testid="member-info-card-delete-btn"
            icon={<DeleteFilled />}
          >
            {tCommon('button.delete')}
          </Button>
        </Popconfirm>
      )}
    </LeftSpace>
  )
}

const RightActions = ({ onCancel, onSave }: RightActionsProps) => {
  const { t: tCommon } = useTranslation('common')

  return (
    <RightSpace>
      <Button
        onClick={onCancel}
        type="text"
        size="small"
        data-testid="member-info-card-cancel-btn"
        data-cy="member-info-card-cancel-btn"
      >
        {tCommon('button.cancel')}
      </Button>
      <Button
        onClick={onSave}
        type="primary"
        size="small"
        data-testid="member-info-card-save-btn"
        data-cy="member-info-card-save-btn"
      >
        {tCommon('button.save')}
      </Button>
    </RightSpace>
  )
}

export const MemberInfoCard = ({
  title,
  titleIcon,
  content,
  updatedAt,
  editing = false,
  onSave,
  onCancel,
  deletable = false,
  onDelete,
  ...rest
}: MemberInfoCardProps) => {
  const showUpdatedAt = !editing

  return (
    <StyledCard
      size="small"
      title={
        title && (
          <EllipsisSpace>
            {titleIcon && (
              <span
                css={`
                  font-size: 14px;
                `}
              >
                {titleIcon}
              </span>
            )}
            {title && (
              <Typography.Text
                data-cy="member-info-card-title"
                ellipsis
                title={title}
              >
                {title}
              </Typography.Text>
            )}
          </EllipsisSpace>
        )
      }
      actions={
        editing
          ? [
              <LeftActions
                deletable={deletable}
                onDelete={onDelete}
                key="left-actions"
              />,
              <RightActions
                onCancel={onCancel}
                onSave={onSave}
                key="right-actions"
              />,
            ]
          : undefined
      }
      {...rest}
    >
      <Typography
        css={`
          font-size: 1.6rem;
        `}
      >
        {content}
      </Typography>
      {showUpdatedAt && <UpdatedAt updatedAt={updatedAt} />}
    </StyledCard>
  )
}
