import { combineReducers } from 'redux'

import addDependent from './add-dependent'
import { apiReducers } from './api'
import app from './app'
import appointments from './appointments'
import authentification from './authentification'
import chat from './chat'
import createProfile from './create-profile'
import dashboardLayout from './dashboard-layout'
import documents from './documents'
import eligibilitySearch from './eligibility-search'
import episodeMeta from './episode-meta/reducer'
import episodeQueues from './episode-queues/reducer'
import episodeView from './episode-view/reducer'
import episodes from './episodes'
import icbt from './icbt'
import inputHealth from './input-health/reducer'
import locations from './locations'
import medicalProfile from './medical-profile'
import memberCharges from './member-charges'
import memberEligibilityInterval from './member-eligibility-interval'
import membersServices from './members-services'
import mentions from './mentions'
import multipass from './multipass'
import navigationSidebar from './navigation-sidebar'
import patientSearch from './patient-search'
import patients from './patients'
import practitioners from './practitioners'
import preferences from './preferences'
import questionnaires from './questionnaires'
import questionnairesGraph from './questionnaires/questionnaires-graph'
import rasaIntents from './rasa-intents'
import reminders from './reminders'
import rlac from './rlac'
import scheduling from './scheduling'
import statusPage from './statuspage'
import structuredNotes from './structured-notes'
import telephone from './telephone/reducer'
import templates from './templates/reducer'
import theming from './theming'
import timekeeper from './timekeeper'
import userManagement from './user-management/reducer'
import userStatus from './user-status/reducer'
import videoCall from './video-call/reducer'

export const reducersMap = {
  addDependent,
  app,
  appointments,
  authentification,
  chat,
  createProfile,
  documents,
  eligibilitySearch,
  episodeMeta,
  episodes,
  episodeQueues,
  episodeView,
  medicalProfile,
  memberCharges,
  membersServices,
  memberEligibilityInterval,
  mentions,
  multipass,
  navigationSidebar,
  patients,
  patientSearch,
  practitioners,
  preferences,
  rasaIntents,
  reminders,
  rlac,
  scheduling,
  statusPage,
  structuredNotes,
  telephone,
  templates,
  theming,
  timekeeper,
  userManagement,
  userStatus,
  videoCall,
  inputHealth,
  dashboardLayout,
  icbt,
  questionnaires,
  questionnairesGraph,
  locations,
  ...apiReducers,
}

export default combineReducers(reducersMap)
