import { all } from 'typed-redux-saga/macro'

import adminEventSagas from './admin-events'
import appointmentTypeSagas from './appointment-types'
import draftSagas from './drafts'
import providersSagas from './providers'
import filterGroupScheduleSagas from './schedule/filter-group-schedule'
import providerScheduleSagas from './schedule/provider-schedule'

export default function* timekeeperSagas() {
  yield* all([
    adminEventSagas(),
    appointmentTypeSagas(),
    draftSagas(),
    filterGroupScheduleSagas(),
    providerScheduleSagas(),
    providersSagas(),
  ])
}
