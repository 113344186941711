import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { MoreOutlined } from '@ant-design/icons'
import { datadogLogs } from '@datadog/browser-logs'
import type { Pharmacy } from '@dialogue/services/dist/emerald/v2/model'
import { Alert, Dropdown, Empty, type MenuProps, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import type { CSSProp } from 'styled-components'

import { AddressDisplay } from 'app/components/address-display'
import {
  MemberInfoCard,
  type MemberInfoCardProps,
} from 'app/containers/member-profile-page/member-info-card'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useUnsetPreferredPharmacyMutation } from 'app/redux/api/emergency-room/members'
import { selectHasPermissions } from 'app/redux/authentification/selectors'
import {
  pharmaciesActions,
  selectPharmacyError,
  selectPharmacyFetching,
  selectEmeraldPreferredPharmacy,
  selectPreferredPharmacy,
} from 'app/redux/medical-profile/pharmacies'
import { ER_UPDATE_MEMBER } from 'app/scopes'

import { RemovePreferredPharmacyModal } from './remove-pharmacy-modal'

export const usePreferredPharmacy = (patientId: string | number) => {
  const emeraldPharmacy = useAppSelector((state) =>
    selectEmeraldPreferredPharmacy(state, patientId),
  )
  const pharmacy = useAppSelector((state) =>
    selectPreferredPharmacy(state, patientId),
  )
  const fetching = useAppSelector(selectPharmacyFetching)
  const error = useAppSelector(selectPharmacyError)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (patientId) {
      dispatch(
        pharmaciesActions.getPreferred({
          patientId: patientId?.toString(),
        }),
      )
    }
  }, [dispatch, patientId])

  // If Emerald pharmacy exists, use that first
  if (emeraldPharmacy) {
    return { pharmacy: emeraldPharmacy, fetching, error }
  }

  // pharmacy from Emerald doesn't exist, use ER data if it exists
  if (pharmacy) {
    // Map ER data to Emerald model
    const mappedPharmacy: Pharmacy = {
      city: pharmacy.locality,
      fax: pharmacy.fax_number,
      id: pharmacy.id.toString(),
      name: pharmacy.name,
      phone: pharmacy.phone_number,
      postal_code: pharmacy.postal_code,
      province: pharmacy.admin_area_level_1_iso_code,
      street_address1: pharmacy.street_number,
      street_address2: pharmacy.street_number2 ?? null,
      // following properties are required by Emerald model but don't exist in ER:
      created_at: '',
      updated_at: '',
      country: null,
      phone_ext: null,
    }

    return {
      pharmacy: mappedPharmacy,
      type: pharmacy.pharmacy_type,
      fetching,
      error,
    }
  }

  return { pharmacy: null, fetching, error }
}

export const PreferredPharmacy = ({
  editable = false,
  patientId,
  title,
  titleIcon,
  styles,
}: {
  editable?: boolean
  patientId: string | number
  title?: string
  titleIcon?: ReactNode
  styles?: CSSProp
}) => {
  const { t } = useTranslation()
  const { t: tCommon } = useTranslation('common')

  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState(false)
  const { pharmacy, type, fetching, error } = usePreferredPharmacy(patientId)
  const dispatch = useAppDispatch()

  const [unsetMemberPharmacy] = useUnsetPreferredPharmacyMutation()

  let content: ReactNode = null

  if (error) {
    content = (
      <Alert
        showIcon
        message={t('medicalProfile.preferredPharmacy.error')}
        type="error"
      />
    )
  } else if (pharmacy === null) {
    content = (
      <Empty
        imageStyle={{ height: '50px', margin: 0 }}
        description={t('medicalProfile.preferredPharmacy.empty')}
      ></Empty>
    )
  } else if (pharmacy) {
    content = (
      <AddressDisplay
        city={pharmacy.city}
        country={pharmacy.country}
        fax={pharmacy.fax}
        name={pharmacy.name}
        phone={pharmacy.phone}
        phone_ext={pharmacy.phone_ext}
        postal_code={pharmacy.postal_code}
        province={pharmacy.province}
        street_address1={pharmacy.street_address1}
        street_address2={pharmacy.street_address2}
        pharmacy_type={type}
      />
    )
  }

  const handleRemovePreferredPharmacy = useCallback(() => {
    unsetMemberPharmacy({ patientId: Number(patientId) })
      .unwrap()
      .then(() => {
        setIsRemovalModalOpen(false)
        // We have to trigger the refetch manually since we're still fetching preferred pharmacy through sagas
        // TODO: remove this once we're using RTKq to get the member's preferred pharmacy
        dispatch(
          pharmaciesActions.getPreferred({ patientId: String(patientId) }),
        )
      })
      .catch((error) => {
        datadogLogs.logger.error(
          'Failed to unset preferred pharmacy',
          { memberId: patientId },
          error,
        )
        notification.error({
          message: t('patientProfile.pharmacy.removeModal.errorMessage'),
        })
      })
  }, [dispatch, patientId, setIsRemovalModalOpen, t, unsetMemberPharmacy])

  const handleOpenRemovalModal = useCallback(
    () => setIsRemovalModalOpen(true),
    [setIsRemovalModalOpen],
  )
  const handleCloseRemovalModal = useCallback(
    () => setIsRemovalModalOpen(false),
    [setIsRemovalModalOpen],
  )

  const hasUpdateMemberScope = useAppSelector((state) =>
    selectHasPermissions(state, [ER_UPDATE_MEMBER]),
  )

  const actionItems = useMemo<NonNullable<MenuProps['items']>>(() => {
    return [
      {
        disabled: !hasUpdateMemberScope,
        key: 'unset-pharmacy',
        label: tCommon('button.remove'),
        onClick: handleOpenRemovalModal,
        'data-dd-action-name': 'preferred-pharmacy:remove',
      },
    ]
  }, [tCommon, handleOpenRemovalModal, hasUpdateMemberScope])

  const actionsMenu = useMemo<MemberInfoCardProps['extra']>(() => {
    return (
      <>
        <Dropdown
          trigger={['click']}
          menu={{
            items: actionItems,
            // @ts-expect-error Passing arbitrary HTML props is allowed
            'data-dd-privacy': 'allow',
          }}
        >
          <MoreOutlined data-dd-action-name="preferred-pharmacy:open:menu" />
        </Dropdown>
        <RemovePreferredPharmacyModal
          handleClose={handleCloseRemovalModal}
          handleRemovePharmacy={handleRemovePreferredPharmacy}
          open={isRemovalModalOpen}
        />
      </>
    )
  }, [
    actionItems,
    handleCloseRemovalModal,
    handleRemovePreferredPharmacy,
    isRemovalModalOpen,
  ])

  return (
    <MemberInfoCard
      loading={fetching}
      titleIcon={titleIcon}
      title={title}
      content={content}
      extra={editable && pharmacy ? actionsMenu : null}
      updatedAt={pharmacy?.updated_at}
      css={styles}
    />
  )
}
