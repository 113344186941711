import { AppointmentStatus, type Schedule } from '@dialogue/timekeeper'
import {
  createSelector,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'

import {
  makeScheduleState,
  type ScheduleRange,
  type ScheduleState,
  scheduleStateOps,
} from './common'

interface FilterGroupState extends ScheduleState {
  /**
   * List of provider IDs who match loaded filter group
   * and are actively scheduled.
   */
  providerIds: number[]
  bookableProviderIds: number[]
  bookedApptProviderIds: number[]
}

interface FilterGroups {
  [key: string]: FilterGroupState
}

type State = FilterGroups & {
  // FIXME: this is not type-inferred correctly because it's incompatible with the index signature from FilterGroups :(
  activeGroupHash: string | null
}

export interface Filters {
  languages: string[] | null
  locations: string[] | null
  practices: string[] | null
  pacLocations: string[] | null
}

type FilterGroupScheduleAction<T = {}> = PayloadAction<
  { filters: Filters; range: ScheduleRange } & T
>

const INITIAL_STATE = {
  activeGroupHash: null,
} as State

const makeFilterGroupScheduleState = (): FilterGroupState =>
  makeScheduleState({
    providerIds: [],
    bookableProviderIds: [],
    bookedApptProviderIds: [],
  })

const hashFilter = (filterValues: string[] | null) => {
  if (!filterValues || filterValues.length === 0) {
    return '<NONE>'
  }
  // sort to ensure stable key regardless of toggle order
  return [...filterValues].sort().join(',')
}

export const getHashForFilters = (filters: Filters) => {
  return [
    `languages:${hashFilter(filters.languages)}`,
    `locations:${hashFilter(filters.locations)}`,
    `practices:${hashFilter(filters.practices)}`,
    `pacLocations:${hashFilter(filters.pacLocations)}`,
  ].join('|')
}

export const { actions: filterGroupScheduleActions, reducer } = createSlice({
  name: 'timekeeper/filterGroupSchedule',
  initialState: INITIAL_STATE,
  reducers: {
    get(state, action: FilterGroupScheduleAction) {
      const { filters, range } = action.payload
      const hash = getHashForFilters(filters)

      if (!state[hash]) {
        state[hash] = makeFilterGroupScheduleState()
      }

      const filterGroupState = state[hash]

      scheduleStateOps.get(filterGroupState, range)
    },
    received(
      state,
      action: FilterGroupScheduleAction<{ schedules: Schedule[] }>,
    ) {
      const { filters, range } = action.payload
      const hash = getHashForFilters(filters)
      const filterGroupState = state[hash]

      scheduleStateOps.received(filterGroupState, range)

      // Reset & recalucate the following lists
      filterGroupState.providerIds = []
      filterGroupState.bookableProviderIds = []
      filterGroupState.bookedApptProviderIds = []

      for (const schedule of action.payload.schedules) {
        // All returned provider IDs
        filterGroupState.providerIds.push(schedule.provider_id)

        // Providers who have at least one bookable shift in the requested range
        if (schedule.availability_constraints.some((pac) => pac.bookable)) {
          filterGroupState.bookableProviderIds.push(schedule.provider_id)
        }

        // Providers who have at least one active booked appointment in the requested range
        if (
          schedule.appointments.some(
            (appt) =>
              appt.status !== AppointmentStatus.Cancelled &&
              appt.status !== AppointmentStatus.NoShow,
          )
        ) {
          filterGroupState.bookedApptProviderIds.push(schedule.provider_id)
        }
      }

      state.activeGroupHash = hash
    },
    erroredFetching(
      state,
      action: FilterGroupScheduleAction<{ error: Error }>,
    ) {
      const { filters, error } = action.payload
      const hash = getHashForFilters(filters)
      const filterGroupState = state[hash]

      scheduleStateOps.erroredFetching(filterGroupState, error)

      state.activeGroupHash = hash
    },
    /**
     * Optionally clear the state for a filter group
     * (e.g. when navigating away from schedule)
     * to ensure a fresh load the next time we render
     * the schedule for said filter + groups.
     */
    clear(state, action: PayloadAction<{ filters: Filters }>) {
      const { filters } = action.payload
      const hash = getHashForFilters(filters)
      delete state[hash]
    },
  },
})

export default reducer

export const selectFilterGroupScheduleState = createSelector(
  (state: ReduxState, filters?: Filters) => {
    const hash = filters
      ? getHashForFilters(filters)
      : selectActiveFilterGroup(state)
    if (!hash) return
    return state.timekeeper.filterGroupSchedule[hash]
  },
  (filterGroupState) => filterGroupState || makeFilterGroupScheduleState(),
)

export const selectFilterGroupLoading = (
  state: ReduxState,
  filters?: Filters,
) => selectFilterGroupScheduleState(state, filters).isLoading

export const selectFilterGroupProviderIds = (
  state: ReduxState,
  filters?: Filters,
) => selectFilterGroupScheduleState(state, filters).providerIds

export const selectFilterGroupBookableProviders = (
  state: ReduxState,
  filters?: Filters,
) => selectFilterGroupScheduleState(state, filters).bookableProviderIds

export const selectFilterGroupBookedApptProviders = (
  state: ReduxState,
  filters?: Filters,
) => selectFilterGroupScheduleState(state, filters).bookedApptProviderIds

export const selectActiveFilterGroup = (state: ReduxState) =>
  state.timekeeper.filterGroupSchedule.activeGroupHash
